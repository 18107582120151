































































































































.dlbutton {
  @apply mt-8 text-center text-white;

  a {
    @apply bg-green text-white font-bold py-4 px-6;
  }

  a:hover {
    @apply bg-green-dark no-underline;
  }
}

#Notice {
  color:white;
  font-size:14px;

}
