

































.action-item {
  flex-basis: 127px;
  height: 127px;
  margin: 0.2rem;
  padding: 0.5rem;
  overflow: hidden;
  background-position: center;
  background-size: cover;

  display: flex;
  align-items: center;

  a {
    color: white;
    text-decoration: none;
  }
}

.action-item:nth-child(7n + 1) {
  @apply bg-green;
}
.action-item:nth-child(7n + 2) {
  @apply bg-red;
}
.action-item:nth-child(7n + 3) {
  @apply bg-orange;
}
.action-item:nth-child(7n + 4) {
  @apply bg-blue;
}
.action-item:nth-child(7n + 5) {
  @apply bg-teal;
}
.action-item:nth-child(7n + 6) {
  @apply bg-indigo;
}
.action-item:nth-child(7n + 7) {
  @apply bg-purple;
}

.action-item:nth-child(7n + 1):hover {
  @apply bg-green-dark;
}
.action-item:nth-child(7n + 2):hover {
  @apply bg-red-dark;
}
.action-item:nth-child(7n + 3):hover {
  @apply bg-orange-dark;
}
.action-item:nth-child(7n + 4):hover {
  @apply bg-blue-dark;
}
.action-item:nth-child(7n + 5):hover {
  @apply bg-teal-dark;
}
.action-item:nth-child(7n + 6):hover {
  @apply bg-indigo-dark;
}
.action-item:nth-child(7n + 7):hover {
  @apply bg-purple-dark;
}

.action-description {
  display: none;
}

.action-item:hover {
  .action-title {
    display: none;
  }
  .action-description {
    display: block;
  }
}
